const $ = require('jquery');

const LazyAllTheThings = require('./lazy-all-things.js');

var Flickity = require('flickity-fade');
require('flickity-bg-lazyload');
require('@fancyapps/fancybox');
var autocomplete = require('jquery-ui/ui/widgets/autocomplete');
require('jquery-pjax');
window.Flickity = Flickity;

const initLazyLoad = () => {
  const lazy = new LazyAllTheThings($, window,
    ['.lazy-src', '.lazy-style', '.lazy-video']);

  $(window).scroll(function () {
    lazy.update();
  });
  lazy.update();
}

window.initLazyLoad = initLazyLoad;


$(document).ready(function () {

  $(document).on('click', '.js-international-banner-accept', function () {
    var date = new Date();
    date.setDate(date.getDate() + 30);
    var dateSr = date.toUTCString();
    document.cookie = "__privacy=1; expires=" + dateSr;
    $(this).closest('.js-international-banner').remove();
  });

  $(document).on('click', '.js-international-banner-close', function () {
    document.cookie = "__privacy=-1";
    $(this).closest('.js-international-banner').remove();
  });


  //----------------------------------------------------------------------------
  //Image lazyload
  //----------------------------------------------------------------------------
  initLazyLoad();

  //----------------------------------------------------------------------------
  //Header scroll
  //----------------------------------------------------------------------------
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('.js-site-header').outerHeight();

  $(window).scroll(function (event) {
    didScroll = true;
    var lastScrollTop = $(window).scrollTop();

    if (lastScrollTop < 250) {
      $('body').removeClass('scrolling');
    } else {
      $('body').addClass('scrolling');
    }
  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = $(this).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta)
      return;

    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $('body').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $('body').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }


  // ---------
  // ---------
  $(document).on('click', ".js-modal-service a", function () {
    if ("#register" == $(this).attr('href') || "#pricing" == $(this).attr('href')) {
      $.fancybox.close();
    }
  });

  //----------------------------------------------------------------------------
  //Main nav toggle
  //----------------------------------------------------------------------------
  $(".js-mainnav-open").on('click', function (e) {
    $(this).addClass('active');
    $('.js-mainnav').addClass('active');
    $('body').addClass('mainnav-active');
    return false;
  });
  $(".js-mainnav-close").on('click', function (e) {
    $(this).toggleClass('active');
    $('.js-mainnav').removeClass('active');
    $('body').removeClass('mainnav-active');
    return false;
  });

  $(".js-mainnav-overlay").on('click', function (e) {
    $(this).removeClass('active');
    $('body').removeClass('mainnav-active');
    $('.js-mainnav').removeClass('active');
    $('.js-nav-toggle').removeClass('active');
    return false;
  });


  //----------------------------------------------------------------------------
  //Site search toggle
  //----------------------------------------------------------------------------
  $(".js-site-search-open").on('click', function (e) {
    $('.js-site-search').addClass('active');
    $('body').addClass('site-search-active');
    $('.js-site-search__input').focus();

    // setTimeout(function() {
    //     $('.js-site-search__input').focus();
    // }, 1000);

    return false;
  });
  $(".js-site-search-close").on('click', function (e) {
    $('.js-site-search').removeClass('active');
    $('body').removeClass('site-search-active');
    return false;
  });

  //----------------------------------------------------------------------------
  //info centre search autocomplete form
  //----------------------------------------------------------------------------
  if ($('.js-search-infocentre-input').length) {
    $('.js-search-infocentre-input').autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/discover/autocomplete',
          dataType: 'json',
          data: {term: request.term},
          success: function (data) {
            if (!data.length) {
              response([{
                noResultsFound: 1
              }]);
            } else {
              response($.map(data, function (item) {
                return {
                  label: item.title,
                  value: item.slugfragment,
                };
              }));
            }
          },
        });
      },
      minLength: 1,
      select: function (event, ui) {
        event.preventDefault();
        if (typeof ui.item.noResultsFound === 'undefined') {
          var protocol = location.protocol;
          var slashes = protocol.concat('//');
          var host = slashes.concat(window.location.hostname);
          window.location.href = '/discover/article/' +
            ui.item.value;
        }
      },
      focus: function (event, ui) {
        event.preventDefault(); // or return false;
      },
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
      if (typeof item.noResultsFound !== 'undefined') {
        return $("<li class=\"autocomplete-search-option autocomplete-search-option--no-results\"><div>Sorry, we can't find anything with your search query.</div></li>")
          .appendTo(ul);
      } else {
        var newText = String(item.label).replace(
          new RegExp(this.term, 'gi'),
          '<span class=\'autocomplete-highlight\'>$&</span>');

        return $("<li></li>")
          .data('item.autocomplete', item)
          .append('<div>' + newText + '</div>')
          .appendTo(ul);
      }

    };
  }

  $(".js-infocentre-form").submit(function (ev) {
    if ($("#infocentre-input").val() == '') {
      ev.preventDefault();
    }
  });

  if ($('.js-search-covid-section-input').length) {
    $('.js-search-covid-section-input').autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/covid/autocomplete',
          dataType: 'json',
          data: {term: request.term},
          success: function (data) {
            if (!data.length) {
              response([{
                noResultsFound: 1
              }]);
            } else {
              response($.map(data, function (item) {
                return {
                  label: item.title,
                  value: item.slugfragment,
                };
              }));
            }
          },
        });
      },
      minLength: 1,
      select: function (event, ui) {
        event.preventDefault();
        if (typeof ui.item.noResultsFound === 'undefined') {
          var protocol = location.protocol;
          var slashes = protocol.concat('//');
          var host = slashes.concat(window.location.hostname);
          window.location.href = '/covid-19/article/' +
            ui.item.value;
        }
      },
      focus: function (event, ui) {
        event.preventDefault(); // or return false;
      },
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
      if (typeof item.noResultsFound !== 'undefined') {
        return $("<li class=\"autocomplete-search-option autocomplete-search-option--no-results\"><div>Sorry, we can't find anything with your search query.</div></li>")
          .appendTo(ul);
      } else {
        var newText = String(item.label).replace(
          new RegExp(this.term, 'gi'),
          '<span class=\'autocomplete-highlight\'>$&</span>');

        return $("<li></li>")
          .data('item.autocomplete', item)
          .append('<div>' + newText + '</div>')
          .appendTo(ul);
      }

    };
  }

  $(".js-covid-section-form").submit(function (ev) {
    if ($("#covid-section-input").val() == '') {
      ev.preventDefault();
    }
  });


  //----------------------------------------------------------------------------
  //Main nav - level 2 toggle
  //----------------------------------------------------------------------------
  $(document).on('click', '.js-mainnav-level2-toggle', function (ev) {
    ev.preventDefault();
    ev.stopPropagation();

    const $this = $(this);
    const $nav = $this.parent().find('.js-mainnav-level2');
    $('.js-mainnav-level2').not($nav).removeClass('active');

    $('.js-mainnav-level2-toggle').not($this).removeClass('active');
    $this.toggleClass('active');

    var $li = $this.closest('li')
    setTimeout(function () {
      $('.js-mainnav').animate({
        scrollTop: $li.position().top - 10
      }, 400);

      $nav.toggleClass('active');
    }, 400);
  });


  //----------------------------------------------------------------------------
  //Accordion
  //----------------------------------------------------------------------------
  var allPanels = $('.accordion__copy');

  $('.button--accordion-toggle').click(function () {
    $('.button--accordion-toggle').not(this).removeClass('active');
    allPanels.not($(this).parent().next()).removeClass('active');
    $(this).toggleClass('active');
    $(this).parent().next().toggleClass('active');
    return false;
  });


  //----------------------------------------------------------------------------
  //Wysiwyg table wrapper for overflow
  //----------------------------------------------------------------------------
  $(".wysiwyg table").wrap("<div class='table-wrapper'/>");


  //----------------------------------------------------------------------------
  //Toggle button
  //----------------------------------------------------------------------------
  $(".js-toggle-button").on('click', function (e) {
    $(this).toggleClass('active');
    var el = $(this),
      target = el.data('target');
    $(target).toggleClass('active');
    return false;
  });

  $(".js-cat-tab li").on('click', function (ev) {
    ev.preventDefault();
    $(".js-cat-tab li a").removeClass('active');
    $(".js-tabs").hide();
    const catId = $(this).find('a').data('idx');
    $("#" + catId).show();
    $(this).find('a').addClass('active');
    initLazyLoad();
  });

  //community toast
  if (!$('#lastCommunityTitle').length) {
    var lastCommunity = localStorage.getItem('lastCommunity');
    if (lastCommunity) {
      lastCommunity = JSON.parse(lastCommunity);
      $('.js-toast--community').find('.js-toast--community__title').text(lastCommunity.title);
      $('.js-toast--community').find('.js-toast--community__img').prop('style', lastCommunity.img)
      $('.js-toast--community').find('.js-toast--community__link').prop('href', lastCommunity.link);
      $('.js-toast--community').addClass('active');

      $(document).on('click', '.js-toast--community__close', function (e) {
        localStorage.setItem('lastCommunity', '');
        $('.js-toast--community').removeClass('active');
      });
    }
  }

  // used in community detail page and landing page(Accommodation options block)
  $(document).on('click', '.js-accommodation-option', function () {
    var idx = $(this).data('idx')

    $('.js-accommodation-option').not(this).removeClass('active');
    $(this).addClass('active');


    $('.js-accommodation-container').not('.js-accommodation-container-' + idx).hide();
    $('.js-accommodation-container-' + idx).show();

    if (!$('.js-accommodation-container-' + idx + '-gallery').hasClass('activated')) {
      var flkty = new Flickity('.js-accommodation-container-' + idx + '-gallery .slides--gallery', {
        cellAlign: 'left',
        contain: true,
        bgLazyLoad: 1,
        adaptiveHeight: true
      });
    }

    // $.fancybox.open({
    //     src: modalId,
    //     type: 'inline',
    //     touch: false,
    //     afterShow: function () {

    //     }
    // });

    return false;
  });

  //check if there is value in search input
  if ($('#input-site-search').val().length == 0) {
    $('.js-search-btn').prop('disabled', true);
  }

  $('#input-site-search').keydown(function (e) {
    if (e.keyCode == 13) {
      if ($(this).val().length == 0) {
        e.preventDefault();
        return false;
      }
    }
  });

  $('#input-site-search').on('input change', function () {
    if ($(this).val() != '') {
      $('.js-search-btn').prop('disabled', false);
    } else {
      $('.js-search-btn').prop('disabled', true);
    }
  });


  //site search
  $('.js-site-search__input').autocomplete({
    source: function (request, response) {
      $.ajax({
        url: '/site-search/search',
        dataType: 'json',
        data: {term: request.term},
        success: function (data) {
          if (!data.length) {
            response([{
              noResultsFound: 1
            }]);
          } else {
            response($.map(data, function (item) {
              return item;
            }));
          }
        },
      });
    },
    minLength: 1,
    select: function (event, ui) {
      event.preventDefault();

      if (event.keyCode === 13) {
        location.href = '/search-results?q=' + $(this).val();
      }
      $(this).val('');
      if (typeof ui.item.noResultsFound === 'undefined') {
        if (ui.item.url.indexOf('/assets') !== -1) {
          window.open(ui.item.url);
        } else {
		      window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'searchResult',
            'url': ui.item.url
          });
          location.href = ui.item.url;
        }
      }
    },
  }).data('ui-autocomplete')._renderItem = function (ul, item) {
    $(ul).addClass('site-search-search-options');
    var html = null;
    if (typeof item.noResultsFound !== 'undefined') {
      html = `<li class="autocomplete-search-option autocomplete-search-option--no-results">
                                Sorry, we can't find anything with your search query.
                            </li>`;
    } else {
      if (item.photo) {
        html = `<li class="autocomplete-search-option">
                                <a ${item.url.indexOf('/assets') !== -1 ? 'target="_blank"' : ''} class="autocomplete-search-option__link autocomplete-search-option__link--center" href="${item.url}">
                                    <div class="autocomplete-search-option__col1">
                                        <figure class="autocomplete-search-option__img aspect-ratio--6x4 image-bg" style="background-image: url('${item.photo}')"></figure>
                                    </div>
                                    <div class="autocomplete-search-option__col2">
                                        ${String(item.title).replace(new RegExp(this.term, 'gi'), '<span class="autocomplete-highlight">$&</span>')}
                                        <div class="autocomplete-search-option__meta">
                                            ${displaySiteSearchSubtitle(item.subtitle, this.term)}
                                        </div>
                                    </div>
                                </a>
                            </li>`;
      } else {
        html = `<li class="autocomplete-search-option">
                                <a ${item.url.indexOf('/assets') !== -1 ? 'target="_blank"' : ''} class="autocomplete-search-option__link" href="${item.url}">
                                    <div class="autocomplete-search-option__col1">
                                        <span class="autocomplete-search-option__module-name heading--copy">${item.text}</span>
                                    </div>
                                    <div class="autocomplete-search-option__col2">
                                        ${String(item.title).replace(new RegExp(this.term, 'gi'), '<span class="autocomplete-highlight">$&</span>')}
                                        <div class="autocomplete-search-option__meta">
                                            ${displaySiteSearchSubtitle(item.subtitle, this.term)}
                                        </div>
                                    </div>
                                </a>
                            </li>`;
      }
    }


    return $(html).appendTo(ul);
  };

  //gallery
  $(document).on('click', '.js-village-hero-gallery', function () {
    var modalId = $(this).data('src');
    $.fancybox.open({
      src: modalId,
      type: 'inline',
      touch: false,
      afterShow: function () {
        var flkty = new Flickity(modalId + ' .slides--full-width', {
          cellAlign: 'center',
          contain: false,
          wrapAround: false,
          bgLazyLoad: 1,
          adaptiveHeight: true
        });
      }
    });

    return false;
  });


  //----------------------------------------------------------------------------
// Pathway care - steps
//----------------------------------------------------------------------------
  $(document).ready(function ($) {
    $(document).on('click', 'body', function (e) {
      if ($(e.target).closest('.js-filter-item').length !== 1) {
        $('.js-interested-community-toggle-button').removeClass('active');
        $('.js-interested-community-toggle-target').removeClass('active');
      }
    });

    $(document).on('click', '.js-interested-community-toggle-button', function (e) {
      $('.js-interested-community-toggle-button').not(this).removeClass('active');
      $(this).toggleClass('active');

      $('.js-interested-community-toggle-target').removeClass('active');
      if ($(this).hasClass('active')) {
        $($(this).data('target')).addClass('active');
      }

      return false;
    });

    $(document).on('change', '.js-interested-community-form-option', function (e) {
      $(".js-picked-community-count").text($(".js-interested-community-toggle-target .js-interested-community-form-option:checked").length);
      var pickedCommunities = [];
      $(".js-interested-community-toggle-target .js-interested-community-form-option:checked").each(function (ind, obj) {
        pickedCommunities.push($(this).val());
      });

      $("[id$=_interestCommunities]").val(pickedCommunities.join(','));
    });

    $(document).on('change', '.js-pathway-map-region', function (e) {
      if ($(this).prop('checked') == true) {
        $("label[for=" + $(this).attr('id') + "]").removeClass('pathway-location__pin--select').addClass('pathway-location__pin--select');
      } else {
        $("label[for=" + $(this).attr('id') + "]").removeClass('pathway-location__pin--select');
      }
    });

//----------------------------------------------------------------------------
    // Guide - steps
    //----------------------------------------------------------------------------
    $(document).on('submit', '#js-guide-step1', function (event) {
      var $form = $(this);
      var url = $(this).attr('action');
      event.preventDefault();
      //alert('aa');
      $.ajax({
        url: url,
        dataType: 'json',
        method: 'post',
        data: $form.serialize(),
      }).done(function (data) {
        //$("#pathway-care-1").html('aa');
        console.log(data);
        $.fancybox.close();
        $.fancybox.open({
          src: '#pathway-care-2',
          type: 'inline',
          opts: {
            touch: false,
            afterShow: function (instance, current) {
              console.info('done!');
            }
          }
        });
      }).fail(function (err) {

      })
    });

    $(document).on('submit', '#js-guide-step2', function (event) {
      var $form = $(this);
      var url = $(this).attr('action');
      event.preventDefault();
      //alert('aa');
      $.ajax({
        url: url,
        dataType: 'json',
        method: 'post',
        data: $form.serialize(),
        beforeSend: function () {
          if ($(".js-pathway-map-region:checked").length == 0) {
            $(".js-no-region-checked-error").removeClass('visuallyhidden');
            return false;
          } else {
            $(".js-no-region-checked-error").addClass('visuallyhidden');
          }
        }
      }).done(function (data) {
        console.log(data);

        if (data.guideData.whom == 'myself') {
          $(".js-step3-question").html('What is your <em>current status</em>?');
        } else {
          $(".js-step3-question").html('What is the <em>current status</em> of your loved one?');
        }

        $.fancybox.close();
        $.fancybox.open({
          src: '#pathway-care-3',
          type: 'inline',
          opts: {
            touch: false,
            afterShow: function (instance, current) {
              console.info('done!');
            }
          }
        });
      }).fail(function (err) {

      })
    });

    $(document).on('submit', '#js-guide-step3', function (event) {
      var $form = $(this);
      var url = $(this).attr('action');
      event.preventDefault();

      $.ajax({
        url: url,
        dataType: 'json',
        method: 'post',
        data: $form.serialize(),
      }).done(function (data) {
        //$("#pathway-care-1").html('aa');
        console.log(data);
        $.fancybox.close();
        $.fancybox.open({
          src: '#pathway-care-4',
          type: 'inline',
          opts: {
            touch: false,
            afterShow: function (instance, current) {
              console.info('done!');
            }
          }
        });
      }).fail(function (err) {

      })
    });

    $(document).on('submit', '#js-guide-step4', function (event) {
      var $form = $(this);
      var url = $(this).attr('action');
      event.preventDefault();

      $.ajax({
        url: url,
        dataType: 'json',
        method: 'post',
        data: $form.serialize(),
      }).done(function (data) {
        //$("#pathway-care-1").html('aa');
        console.log(data);
        $.fancybox.close();

        $(".js-guide-result-desc").html(data.result);
        $(".js-suggest-communities").html(data.communityHtml);
        $(".js-interested-community-toggle-target").html(data.intrestedCommunityHtml);
        $(".js-suggested-community-count").text(data.communityCount);
        $(".js-pathway-for-whom").val(data.guideData.whom);
        $(".js-pathway-region").val(data.guideData.locations);
        $(".js-pathway-current-status").val(data.guideData.currentStatus);
        $(".js-pathway-care").val(data.guideData.kindOfCare);
        $(".js-pathway-nasc").val(data.guideData.nasc);
        if (data.matchCount == 0) {
          $(".js-communities-available-text").text('Unfortunately, we cannot match your exact care requirements with one of our communities; however, we have these communities nearby:');
        }

        $.fancybox.open({
          src: '#pathway-care-final',
          type: 'inline',
          opts: {
            touch: false,
            afterShow: function (instance, current) {
              console.info('done!');
            }
          }
        });
      }).fail(function (err) {

      })
    });

    $(document).on("click", ".js-guide-prev", function (ev) {
      ev.preventDefault();
      $.fancybox.close();
      var target = $(this).data('target');

      $.fancybox.open({
        src: target,
        type: 'inline',
        opts: {
          touch: false,
          afterShow: function (instance, current) {
            console.info('done!');
          }
        }
      });
    });

    $(document).on('submit', '#js-guide-step-final', function (e) {
      e.preventDefault();
      $.pjax.submit(e, {
        scrollTo: false,
        container: '#js-guide-step-final',
        fragment: '#js-guide-step-final',
        push: false,
      });

    });

    $("#js-guide-step-final").on('pjax:complete', function () {
      var li = [];
      const pickedCommunities = $("[id$=_interestCommunities]").val();
      if (pickedCommunities) {
        var pickedCommunitiesAry = pickedCommunities.split(',');
      } else {
        var pickedCommunitiesAry = [];
      }
      $(".js-suggest-communities h4.heading--card .js-community-name").each(function (ind, obj) {
        const communityName = $(obj).text();
        li.push('<li class="filter-dropdown-field">');
        li.push('<input class="checkbox-styled--input  js-interested-community-form-option" name="care[]" id="opt' + ind + '" type="checkbox" value="' + $(obj).text() + '" ' + (pickedCommunitiesAry.includes(communityName) ? 'checked=true' : '') + '>');
        li.push('<label class="checkbox-styled--label" for="opt' + ind + '">');
        li.push('<i class="icon icon--l icon--checkbox-styled"></i>');
        li.push('<span class="checkbox-styled--label__txt">' + $(obj).text() + '</span>');
        li.push('</label></li>');
      });

      $(".js-interested-community-toggle-target").html(li.join(''));
      $(".js-suggested-community-count").text($(".js-suggest-communities h4.heading--card .js-community-name").length);
      $(".js-picked-community-count").text(pickedCommunitiesAry.length);
      if (0 == pickedCommunitiesAry.length) {
        $(".js-empty-interested-community-error").removeClass('visuallyhidden');
      } else {
        $(".js-empty-interested-community-error").addClass('visuallyhidden');
      }
      recaptchaLoad();
    });
  });

  init();

  // for notification panel
  $(".js-close-top-notification").click(function () {
    localStorage.setItem('closeNotificationDate', new Date());
    $(".js-top-notification").addClass('inactive');
  });

  if (localStorage.getItem('closeNotificationDate')) {
    const date1 = new Date();
    const date2 = new Date(localStorage.getItem('closeNotificationDate'));
    const datediff = DateDiff(date1, date2);
    if (datediff > 0) {
      // over 1 day then show again
      $(".js-top-notification").removeClass('inactive');
    }
  } else {
    $(".js-top-notification").removeClass('inactive');
  }

});

function DateDiff(date1, date2) {
  var datediff = Math.abs(Math.round(date1 - date2)); // difference
  datediff = parseInt(datediff / (24 * 60 * 60 * 1000), 10);
  return datediff;
}


const popModalIdPrefix = '#pop-modal-';
const popModalAnchorIdPrefix = '#pop-modal-anchor-';
const popModalButtonClass = '.js-pop-modal-button';

function init() {
  if ($.support.pjax) {
    $.pjax.defaults.timeout = 30000;

    $(document).on('click', popModalButtonClass, function (event) {
      event.preventDefault();

      var url = $(this).attr('href');

      if ($(this).hasClass('no-urlupdate')) {
        checkModalOpenNoUrlUpdate(url);
      } else {
        history.pushState(null, null, url);
        checkModalOpen(false);
      }

      return false;
    });
  }

  checkModalOpen(true);
};

function boostModal(modelId) {
  if ($(modelId).length > 0) {
    let boosterName = $(modelId).data('booster');
    if (boosterName) {
      let id = $(modelId).data('id');
      let boostArea = $(modelId).find('.js-booster');
      if (id && boostArea.length > 0 && boostArea.data('updated') != 1) {
        boostArea.html(`<div class="cms-block section-inner--s"><p>Loading...</p></div>`);
        $.ajax({
          url: `/booster/${boosterName}?boosterid=${id}`,
          method: 'get',
        }).done(function (data) {
          boostArea.html(data);
          boostArea.data('updated', 1);
        });
      }
    }
  }
};

function checkModalOpen(scroll) {
  var lastUrlFragment = location.hash.split('-').pop();

  if (!isNaN(lastUrlFragment) && $(popModalIdPrefix + lastUrlFragment).length == 1) {
    if (scroll && $(popModalAnchorIdPrefix + lastUrlFragment).length == 1) {
      setTimeout(function () {
        $('html,body').animate({
          scrollTop: $(popModalAnchorIdPrefix + lastUrlFragment).position().top
        }, 0);
      }, 500)
    }

    boostModal(popModalIdPrefix + lastUrlFragment);

    $.fancybox.open({
      src: popModalIdPrefix + lastUrlFragment,
      type: 'inline',
      opts: {
        autoFocus: false,
        touch: false,
        afterClose: function (instance, current) {
          history.pushState(null, null, $(popModalIdPrefix + lastUrlFragment).data('url'))
        }
      }
    });
  }
};

function checkModalOpenNoUrlUpdate(url, scroll = false) {
  var lastUrlFragment = url.split('/').pop();

  if (!isNaN(lastUrlFragment) && $(popModalIdPrefix + lastUrlFragment).length == 1) {
    if (scroll && $(popModalAnchorIdPrefix + lastUrlFragment).length == 1) {
      setTimeout(function () {
        $('html,body').animate({
          scrollTop: $(popModalAnchorIdPrefix + lastUrlFragment).position().top
        }, 0);
      }, 500)
    }

    boostModal(popModalIdPrefix + lastUrlFragment);

    $.fancybox.open({
      src: popModalIdPrefix + lastUrlFragment,
      type: 'inline',
      opts: {
        autoFocus: false,
        touch: false,
        afterClose: function (instance, current) {
          history.pushState(null, null, $(popModalIdPrefix + lastUrlFragment).data('url'))
        }
      }
    });
  }
};

function displaySiteSearchSubtitle(subtitle, term) {
  const DOTDOTDOT = '...';
  const LIMIT = 50;
  const CONTEXT_LENGTH = LIMIT - term.length;
  const HALF_LENGTH = CONTEXT_LENGTH / 2;

  if (subtitle === null) {
    return '';
  }

  var pos = subtitle.toLowerCase().indexOf(term.toLowerCase());
  if (pos == -1) {
    return subtitle.substr(0, LIMIT) + (subtitle.length > LIMIT ? DOTDOTDOT : '');
  } else {
    term = subtitle.substr(pos, term.length);

    var leftSideText = null;
    var rightSideText = null;
    var leftSideLength = HALF_LENGTH;
    var rightSideLength = HALF_LENGTH;
    var leftSideDotDotDot = DOTDOTDOT;
    var rightSideDotDotDot = DOTDOTDOT;

    var leftSideStart = pos - leftSideLength;
    if (leftSideStart <= 0) {
      leftSideStart = 0;
      leftSideDotDotDot = '';
      leftSideLength = pos;
      rightSideLength = rightSideLength + (HALF_LENGTH - pos);
      if ((pos + term.length + rightSideLength) >= subtitle.length) {
        rightSideDotDotDot = '';
      }
    }

    leftSideText = leftSideDotDotDot + subtitle.substr(leftSideStart, leftSideLength);
    rightSideText = subtitle.substr(pos + term.length, rightSideLength) + rightSideDotDotDot;
    var result = leftSideText + term + rightSideText;
    return `${String(result).replace(new RegExp(term, 'gi'), '<span class="autocomplete-highlight">$&</span>')}`;
  }
};

function _prevSiteSearchSubtitleBit(subtitle, term) {

};

function _nextSiteSearchSubtitleBit(subtitle, term) {

};


// Get the navbar
var navbar = document.getElementById("js-vertical-nav");
if (navbar) {
  //----------------------------------------------------------------------------
  //Sticky nav
  //----------------------------------------------------------------------------
  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    myFunction()
  };

  function myFunction() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }

    var $section = $(navbar).closest('section');
    var $header = $('header');
    if ($section.length > 0 && $header.length > 0) {
      var bottomHeightThreshhold = $section.outerHeight() - $header.outerHeight() - 50;
      // console.log(window.pageYOffset, bottomHeightThreshhold)
      if (window.pageYOffset >= bottomHeightThreshhold) {
        navbar.classList.add("vertical-subnav-items--hidden");
        // navbar.classList.remove("sticky");
      } else {
        navbar.classList.remove("vertical-subnav-items--hidden");
      }
    }
  }

  if (window.pageYOffset > 0) {
    $('header').addClass('scrolling');
    setTimeout(function () {
      myFunction();
    }, 500);
  }

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;
  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
}

// ---------------------------------------------------
// recapture parts
// ---------------------------------------------------
var existingRecaptcha = {};

var recaptchaInit = function () {
  var allRecaptcha = document.querySelectorAll('.recaptcha');
  if (!allRecaptcha.length) {
    return;
  }
  var i, c = allRecaptcha.length;
  for (i = 0; i < c; i += 1) {
    var el = allRecaptcha[i];
    var id = el.getAttribute('id');
    var sitekey = el.getAttribute('sitekey');

    if (existingRecaptcha.hasOwnProperty(id) && el.childNodes.length > 0) {
      grecaptcha.reset(existingRecaptcha[id]);
    } else {
      existingRecaptcha[id] = grecaptcha.render(el, {
        "sitekey": sitekey
      });
    }
  }
};

var recaptchaLoad = function () {
  // load re-captcha
  var recaptchaScript = document.createElement('script');
  recaptchaScript.async = true;
  recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaInit&render=explicit';
  document.querySelector('head').appendChild(recaptchaScript);
};

window.recaptchaInit = recaptchaInit;
window.recaptchaLoad = recaptchaLoad;

$(document).ready(function ($) {
  if (document.querySelectorAll('.recaptcha').length > 0) {
    recaptchaLoad();
  }
});

// ---------------------------------------------------
// prevent form submit twice parts
// ---------------------------------------------------
$(document).on('click', ".js-button-submit", function (ev) {
  ev.preventDefault();
  $(this).attr('disabled', true);
  $(this).closest("form").submit();
});


// Trigger d365 to load the specified form and jam it in the end of the supplied container
function triggerD365(container, {formId, formApiUrl, cachedFormUrl}) {
  // nice version
  const form = d365mktforms.createForm(
    formId,
    formApiUrl,
    cachedFormUrl,
  );
  container.insertAdjacentElement('beforeend', form);
}

function rot13(string)
{
  return (string + '')
    .replace(/[a-z]/gi, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + (s.toLowerCase() < 'n' ? 13 : -13))
    })
}

$(document).delegate('a.js-fuzz', 'click', function (ev) {
    const { href = null } = this.dataset;
    if (href) {
      this.href = atob(rot13(href));
      this.querySelector('.js-fuzz-label').innerHTML = this.href.replace('mailto:','').replace(/\?.*$/,'');
      delete this.dataset.href;
    }
});

window.triggerD365 = triggerD365;
module.exports = $;


//----------------------------------------------------------------------------
//Viewport height variable on resize
//----------------------------------------------------------------------------
const viewportHeight = () => {
  document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', viewportHeight);
viewportHeight();